import { stringify, parse } from 'flatted';

const STORAGE_PREFIX = 'airquality_';

const storage = {
  save(key, value) {
    try {
      localStorage.setItem(STORAGE_PREFIX + key, stringify(value));
    } catch (e) {
      console.warn('Failed to save to localStorage:', e);
    }
  },
  
  load(key, defaultValue = null) {
    try {
      const item = localStorage.getItem(STORAGE_PREFIX + key);
      return item ? parse(item) : defaultValue;
    } catch (e) {
      console.warn('Failed to load from localStorage:', e);
      return defaultValue;
    }
  }
};

// Create a singleton state object that will be shared across all stores
const sharedState = {
  _mapFeatures: storage.load('mapFeatures', null),
  
};

// Create references to sync paired variables
Object.defineProperties(sharedState, {
  mapFeatures: {
    get() {
      return this._mapFeatures;
    },
    set(value) {
      this._mapFeatures = value;
      storage.save('mapFeatures', value);
    }
  }
  
});

// Helper function to merge shared state into existing store config
export const mergedWithShared = (storeConfig) => {
  const syncedKeys = [
    'mapFeatures'
  ];
  
  // Create new state that references shared state for synced variables
  const newState = { ...storeConfig.state() };
  
  syncedKeys.forEach(key => {
    Object.defineProperty(newState, key, {
      get() {
        return sharedState[key];
      },
      set(value) {
        sharedState[key] = value;
      },
      enumerable: true,
      configurable: true
    });
  });

  return {
    ...storeConfig,
    state: () => newState
  };
};
